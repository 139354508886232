import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import GlobalServiceLanding from '../components/GlobalServiceLanding';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

export default function SolutionsPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulSolutions?.seoEntry}
          siteurl = {pageContext?.slug}
          bucketName = {pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <GlobalServiceLanding data={data?.contentfulSolutions} basePath={pageContext.basePath}/>
      </Layout>
    </>
  );
}

export const query = graphql`
  query solutionsQuery($contentful_id: String,$locale: String) {
    contentfulSolutions(contentful_id: { eq: $contentful_id },node_locale: { eq: $locale }) {
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      capsulesLeftImage{
        file{
          url
        }
      }
      heroImage {
        description
        file {
          url
        }
      }
      circularSectionTitle
      circularSectionBackgroundImage {
        file {
          url
        }
      }
      circularSectionDescription {
        raw
      }
      globalServicesIntroSection {
        header
        subHeader
        shortDescription {
          raw
        }
        isVideo
        image{
          description
          file{
            url
          }
        }
        videoComponent {
          videoComponent {
            dateOfPublishing
            videoType
            videoId
          }
        }
      }
      solutionsIntroSectionHeaderText
      solutionsIntroSectionShortDescription
      servicesArrowImage {
        description
        file {
          url
        }
      }
      servicesSectionTitle
      servicesSectionDescription {
        raw
      }
      regionCategory
      serviceCategories
      movePhaseCategory
      userCategory
      regionCategoryTiles {
        header
        serviceName {
          raw
        }
        slug
      }
      serviceCategoriesTiles {
        header
        serviceName {
          raw
        }
        slug
      }
      movePhaseCategoryTiles {
        header
        serviceName {
          raw
        }
        slug
      }
      userCategoryTiles {
        serviceName {
          raw
        }
        header
        slug
      }
      spotLightsImage {
        description
        file {
          url
        }
        description  
      }
      spotLightsTitle
      spotLightsDescription {
        raw
      }
      spotLightsTiles {
        backgroundImage {
          file {
            url
          }
          description   
        }
        linkOrPdf
        header {
          raw          
        }
        pdfOrDocument {
          file {
            url
          }
        }       
        tileLink {
          anchorLink
          slug
        }
      }
      rightDonutBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
    }
  }
`;
