import React from 'react';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';

const GlobalServiceTile = ({ header, serviceName, link, basePath }) => {
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return (
    <>
      <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
        <Link to={`/${basePath}/${link}`} tabIndex="0">
          <div className="box box-height-1" locId="header">
            <h4>{header}</h4>
            <p locId="serviceName">
              {serviceName.raw &&
                documentToReactComponents(JSON.parse(serviceName.raw, richtextOptions))}
            </p>
          </div>
        </Link>
      </Grid>
    </>
  );
};

export default GlobalServiceTile;
