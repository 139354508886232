import React from 'react';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './GlobalServiceTabSection.scss';
import clsx from 'clsx';
import { myStyles } from './styles';
import GlobalServiceTile from './GlobalServiceTile';
import Container from '@material-ui/core/Container';

const GlobalServiceTab = ({ data,regionCategory,serviceCategories,movePhaseCategory,userCategory,basePath }) => {
  const arrowImage = data?.servicesArrowImage?.file?.url+'?fm=webp&q=100';
  const altArrowImage = data?.servicesArrowImage?.description;
  const title = data?.servicesSectionTitle;
  const servicesSectionDescription = data?.servicesSectionDescription?.raw;
  const regionCategoryTiles = data?.regionCategoryTiles;
  const serviceCategoriesTiles = data?.serviceCategoriesTiles;
  const movePhaseCategoryTiles = data?.movePhaseCategoryTiles;
  const userCategoryTiles = data?.userCategoryTiles;

  const classes = myStyles(data);
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return (
    <>
    <Container maxWidth="xl">
      <Grid container className="key-service-cls">
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item xs={7} sm={10} md={8} lg={6} xl={6} className="para-1">
            <h2 className="heading" locId="servicesArrowImage">
              <img src={arrowImage} alt={altArrowImage} className="arrow-img" />
              <span>{title}</span>
            </h2>
          </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={10} xl={10} className='para-1'>
            <p className="desp-txt">
              {servicesSectionDescription &&
                documentToReactComponents(JSON.parse(servicesSectionDescription, richtextOptions))}
            </p>
            </Grid>
        </Grid>
      </Grid>
      </Container>

      <Grid className="greybox">
      <Container maxWidth="xl">
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="box-row-1"
          locId="regionCategoryTiles"
          id="region"
        >
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className="box title-box" locId="regionCategory">
              <h3>{regionCategory}</h3>
            </div>
          </Grid>
          {regionCategoryTiles.length > 0 &&
            regionCategoryTiles.map((tile, index) => (
              <>
                <GlobalServiceTile
                  header={tile.header}
                  serviceName={tile.serviceName}
                  link={tile.slug}
                  basePath={basePath}
                />

                {/* {index === 1 && (
                  <Grid item xl={3}>
                    
                  </Grid>
                )} */}
              </>
            ))}
        </Grid>
       </Container>

        <Grid className={clsx('capsules-bg', classes['capsules-bg'])}>
        <Container maxWidth="xl">
        <Grid container className="box-row-2" locId="serviceCategoriesTiles" id="solutiontype">
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className="box title-box" locId="serviceCategories">
              <h3>{serviceCategories}</h3>
            </div>
          </Grid>
          {serviceCategoriesTiles.length > 0 &&
            serviceCategoriesTiles.map((tile, index) => (
              <>
                <GlobalServiceTile
                  header={tile.header}
                  serviceName={tile.serviceName}
                  key={tile.header}
                  link={tile.slug}
                  basePath={basePath}
                />
                {index === 1 && (
                  <>
                    
                    <Grid item xl={4} lg={4}>
                      
                    </Grid>
                  </>
                )}
              </>
            ))}          
            
        </Grid>
        </Container>
        </Grid>
        
        <Container maxWidth="xl">
        <Grid container className="box-row-3" locId="movePhaseCategoryTiles" id="movephase">
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className="box title-box" locId="movePhaseCategory">
              <h3>{movePhaseCategory}</h3>
            </div>
          </Grid>
          {movePhaseCategoryTiles.length > 0 &&
           movePhaseCategoryTiles.map((tile, index) => (
              <>
                <GlobalServiceTile
                  header={tile.header}
                  serviceName={tile.serviceName}
                  key={tile.header}
                  link={tile.slug}
                  basePath={basePath}
                />
                {index === 1 && (
                  <Grid item xl={4} lg={4}>
                      
                  </Grid>
                )}
              </>
            ))}
        </Grid>
        </Container>

        <Container maxWidth="xl">
        <Grid container className="box-row-1 box-row-4" locId="userCategoryTiles" id="audience">
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className="box title-box" locId="userCategory">
              <h3>{userCategory}</h3>
            </div>
          </Grid>
          {userCategoryTiles.length > 0 &&
          userCategoryTiles.map((tile) => (
              <GlobalServiceTile
                header={tile.header}
                serviceName={tile.serviceName}
                key={tile.header}
                link={tile.slug}
                basePath={basePath}
              />
            ))}
        </Grid>
        </Container>
        
        </Grid>
    </>
  );
};

export default GlobalServiceTab;
