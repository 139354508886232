import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles((theme) => ({
  'bg-left-donut': (data) => ({
    background: `url(${data.leftDonutBackgroundImage.file.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-right-donut':(data)=>({
    background: `url(${data.rightDonutBackgroundImage.file.url}?fm=webp&q=100) no-repeat`,
  })
}));
