import React from 'react';
import GlobalServiceHeader from '../GlobalServiceHeader';
import GlobalServiceTab from '../GlobalServiceTabSection';
import GlobalServiceSpotlight from '../GlobalServiceSpotlight';
import Container from '@material-ui/core/Container';
import { myStyles } from './style';
import './GlobalServiceLanding.scss';
import clsx from 'clsx';

const GlobalServiceLanding = ({ data,basePath}) => {
  const classes = myStyles(data);
  return (
    <div className="globalservice-landing-main">
      <div className={clsx('bg-donuts-left', classes['bg-left-donut'])}>
      <div className={clsx('bg-donuts-right', classes['bg-right-donut'])}>
      <div className="globallanding-container-div">
          <GlobalServiceHeader data={data} />
          <GlobalServiceTab
            data={data}
            regionCategory={data?.regionCategory}
            serviceCategories={data?.serviceCategories}
            movePhaseCategory={data?.movePhaseCategory}
            userCategory={data?.userCategory}
            basePath={basePath}
          />
        
          <GlobalServiceSpotlight data={data} basePath={basePath}/>
        
        </div>
      </div>
      </div>
    </div>
  );
};

export default GlobalServiceLanding;
