import React from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderComponent from '../HeaderComponent/Index';
import HeaderIntroComponent from '../HeaderIntroComponent/Index';
import Container from '@material-ui/core/Container';
import { myStyles } from './styles';
import clsx from "clsx"
export default function GlobalServiceHeader({data}) {
  const classes = myStyles(data);
  return (
    <>
      <div className={clsx('landing-hero-img-div', classes['landing-hero-img-div'])}>
        <HeaderComponent
          heroImage={data?.heroImage}
          classes={classes}
          circularSectionTitle={data?.circularSectionTitle}
          circularSectionDescription={data?.circularSectionDescription}
        />
      </div>
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid
          container
          className={clsx('txt-with-video-container', classes['txt-with-video-container'])}>
            <HeaderIntroComponent
            storyhistorySection={data?.globalServicesIntroSection}
            aboutUsStorySectionTopic={data?.solutionsIntroSectionHeaderText}
            aboutUsStorySectionDescription={data?.solutionsIntroSectionShortDescription}
            classes={classes}
          />
      </Grid>
      </Container>
    </>
  )
}


